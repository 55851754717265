'use client';

import './header.css';

import {
  ActionIcon,
  Burger,
  Center,
  Drawer,
  Flex,
  Group,
  Indicator,
  Menu,
  createStyles,
} from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconMoonStars,
  IconSearch,
  IconSun,
} from '@tabler/icons-react';
import { diffDateFromNow, removeScrollStorage } from '@/utils/hook';
import { lazy, useState } from 'react';

import DMText from '@/components/DMText';
import { HEADER_MENU_LIST } from '@/consts/HeaderMenuList';
import Image from 'next/image';
import Link from 'next/link';
import UserInfoButton from '@/features/Main/UserInfoButton';
import dynamic from 'next/dynamic';
import { getLocalStorageItem } from '@/utils/localStorageControl';
import { images } from '@/utils/images';
import { isDark } from '@/utils/utils';
import { mixpanelTrack } from '@/utils/mixpanel';
import themeVariables from '@/styles/themeVariables';
import { useDisclosure } from '@mantine/hooks';
import { useGetTechblogList } from '@/features/Techblog/hook/api';
import { usePathname } from 'next/navigation';

const NotificationBell = dynamic(
  () => import('@/components/NotificationBell'),
  {
    ssr: false,
  }
);

const SearchModal = lazy(() => import('./SearchModal'));

function HeaderComponent() {
  const { dark, toggleColorScheme } = isDark();
  const { classes } = useHeaderStyle({ dark });

  const [hamburgerMenuOpened, hambergerMenuHandler] = useDisclosure(false);

  return (
    <div className={classes.outerHeader}>
      <Flex className={classes.innerHeader}>
        {hamburgerMenuOpened && (
          <Drawer
            opened={hamburgerMenuOpened}
            withCloseButton={false}
            onClose={hambergerMenuHandler.close}
            withOverlay={false}
            classNames={{ inner: classes.hamburgerMenuContainer }}
            styles={{
              content: {
                backgroundColor: dark
                  ? themeVariables.colors.dark
                  : themeVariables.colors.white,
              },
            }}
          >
            <HamburgerMenu
              classes={classes}
              hambergerMenuHandler={hambergerMenuHandler}
            />
          </Drawer>
        )}
        <Group className={classes.logoGroup}>
          <Burger
            opened={hamburgerMenuOpened}
            onClick={hambergerMenuHandler.toggle}
            className={classes.hamburgerButton}
            size="sm"
          />
          <Link href={'/'}>
            <Image
              width={87.66}
              height={19.74}
              src={images.codenary}
              alt="logo"
              priority
              style={{
                filter: dark ? 'invert(100%)' : 'none',
              }}
            />
          </Link>
        </Group>
        <Group spacing={16} className={classes.topmenu}>
          <HeaderMenuList />
        </Group>
        <Group spacing={4} position={'right'} className={classes.userGroup}>
          <ActionIcon
            variant="outline"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => {
              toggleColorScheme();
              mixpanelTrack('DarkMode', 'Clicked');
            }}
            title="Toggle color scheme"
            mr={5}
          >
            {dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
          </ActionIcon>
          <NotificationBell />
          <UserInfoButton />
        </Group>
      </Flex>
    </div>
  );
}

export default HeaderComponent;

const SearchButton = ({ classes }) => {
  const { dark } = isDark();

  const [enter, setEnter] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <ActionIcon
        size={36}
        className={classes.searchButton}
        onClick={() => {
          open();
          mixpanelTrack('HeaderSearchButton', 'Clicked');
        }}
        onMouseEnter={() => {
          setEnter(true);
        }}
      >
        <IconSearch
          size={20}
          stroke={2}
          color={themeVariables.darkMode.neutral(dark)}
        />
      </ActionIcon>
      {enter && (
        <SearchModal
          opened={opened}
          classes={classes}
          dark={dark}
          close={close}
        />
      )}
    </>
  );
};

const HamburgerMenu = ({ classes, hambergerMenuHandler }) => {
  const hamburgerMenuItemOnClick = () => {
    hambergerMenuHandler.close();
  };

  return (
    <div className={classes.hamburgerMenu}>
      <Link href={'/discoveries'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.discoveryIcon}
            alt="discovery-icon"
          />
          디스커버리
        </DMText>
      </Link>
      <Link href={'/company/list'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.techstackIcon}
            alt="techstack-icon"
          />
          기술 스택
        </DMText>
      </Link>
      <Link href={'/company/list'}>
        <DMText
          className={classes.hamburgerMenuMenuItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          기업별로 보기
        </DMText>
      </Link>
      <Link href={'/techstack/list'}>
        <DMText
          className={classes.hamburgerMenuMenuItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          기술별로 보기
        </DMText>
      </Link>
      <Link href={'/statistics/list'}>
        <DMText
          className={classes.hamburgerMenuMenuItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          기술 인기도
        </DMText>
      </Link>
      <Link href={'/calendar/month'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.calendarIcon}
            alt={'schedule-icon'}
          />
          컨퍼런스 일정
        </DMText>
      </Link>
      <Link href={'/architecture/list'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.architectureIcon}
            alt={'architecture-icon'}
          />
          기술 아키텍처
        </DMText>
      </Link>
      <Link href={'/techblog/list'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.techblogIcon}
            alt={'techblog-icon'}
          />
          기술 블로그
        </DMText>
      </Link>
      <Link href={'/jobpost'}>
        <DMText
          className={classes.hamburgerMenuTitleItem}
          dColor={themeVariables.darkMode.normal}
          onClick={hamburgerMenuItemOnClick}
        >
          <Image
            width={30}
            height={30}
            className={classes.hamburgerMenuItemIcon}
            src={images.jobpostIcon}
            alt={'techblog-icon'}
          />
          채용 공고
        </DMText>
      </Link>
    </div>
  );
};

const HeaderMenuList = () => {
  const pathname = usePathname();

  const { dark } = isDark();
  const { classes } = useHeaderStyle({ dark });

  const { data } = useGetTechblogList({
    page: 1,
    tag: '',
    company: '',
    keyword: '',
  });

  const lastPublished = (data || [])[0]?.published;
  const diffPublished = diffDateFromNow(lastPublished);
  const diffToday = diffDateFromNow(
    getLocalStorageItem('read-techblog') || '2024-01-01'
  );

  return (
    <>
      {HEADER_MENU_LIST.map((link) => {
        if (link.dropdownMenu)
          return (
            <Menu key={link.label} trigger={'hover'}>
              <Menu.Target>
                <Link
                  href={link.link}
                  className={classes.link}
                  onClick={removeScrollStorage}
                >
                  <Center>
                    <DMText
                      className={classes.linkLabel}
                      dColor={themeVariables.darkMode.normal}
                      {...themeVariables.size.body2}
                    >
                      {link.label}
                    </DMText>
                    <IconChevronDown
                      size={13}
                      stroke={1.5}
                      color={themeVariables.darkMode.normal(dark)}
                    />
                  </Center>
                </Link>
              </Menu.Target>
              <Menu.Dropdown className={classes.dropdown}>
                <MenuItem link={link} classes={classes} />
              </Menu.Dropdown>
            </Menu>
          );

        return (
          <Indicator
            key={link.link}
            label={''}
            size={5}
            offset={9}
            styles={{
              indicator: {
                backgroundColor: themeVariables.colors.red200,
              },
            }}
            disabled={
              !(
                link.link !== pathname &&
                link.label === '기술 블로그' &&
                diffPublished === 0 &&
                diffToday !== 0
              )
            }
          >
            <Link
              key={link.label}
              href={link.link}
              className={
                link.link == pathname ? classes.currentLink : classes.link
              }
              onClick={removeScrollStorage}
            >
              <DMText
                weight={600}
                dColor={themeVariables.darkMode.normal}
                {...themeVariables.size.body2}
              >
                {link.label}
              </DMText>
            </Link>
          </Indicator>
        );
      })}
    </>
  );
};

const MenuItem = ({ link, classes }) => {
  return (
    <>
      {link.dropdownMenu?.map((item) => (
        <Menu.Item
          key={item.link}
          className={classes.dropdownItem}
          icon={<IconChevronRight size={10} stroke={4} />}
        >
          <Link
            className={classes.dropdownText}
            href={item.link}
            onClick={removeScrollStorage}
          >
            {item.label}
          </Link>
        </Menu.Item>
      ))}
    </>
  );
};

const useHeaderStyle = createStyles((theme, dark: any = {}) => ({
  outerHeader: {
    border: 'none',
    zIndex: 100,
    height: 64,
    position: 'sticky',
    top: 0,
    [theme.fn.smallerThan('md')]: {
      position: 'initial',
      padding: '0px 12px',
    },
    backdropFilter: 'saturate(150%) blur(32px)',
    borderBottom: `1px solid ${themeVariables.lineDark.light300(dark?.dark)}`,
    justifyContent: 'center',
    padding: '0px 10px',
    transition: 'all 0.3s ease',
    backgroundColor: dark?.dark
      ? themeVariables.colors.dark
      : 'rgba(255, 255, 255, 0.88)',
  },

  innerHeader: {
    height: 64,
    display: 'flex',
    width: '100%',
    maxWidth: 1280,
    margin: 'auto',
  },

  topmenu: {
    marginLeft: 18,
    minWidth: 'fit-content',
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  dropdown: {
    border: 'none',
    padding: 12,
    boxShadow: 'rgb(82 63 105 / 15%) 0px 15px 40px 0px',
  },

  logoGroup: {
    minWidth: 100,
    [theme.fn.smallerThan('md')]: {
      gap: 10,
      minWidth: 130,
    },
  },

  userGroup: {
    marginLeft: 'auto',
    width: '100%',
  },

  dropdownItem: {
    height: 42,
    width: 200,
    fontWeight: 500,
    borderRadius: '0.5rem',
    transition: 'all ease 300ms',
    color: dark?.dark
      ? themeVariables.colors.white
      : themeVariables.fontColors.light,
    '&:hover': {
      color: themeVariables.colors.blue300,
      backgroundColor: dark?.dark
        ? 'transparent'
        : themeVariables.colors.blue000,
      transform: 'translateX(6px)',
    },
  },

  dropdownText: {
    padding: 16,
    textDecoration: 'none',
    color: dark?.dark
      ? themeVariables.colors.white
      : themeVariables.fontColors.light,
    fontSize: theme.fontSizes.sm,
    '&:hover': {
      color: themeVariables.colors.blue300,
    },
  },

  searchButton: {
    gap: 0,
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },

  hamburgerMenuContainer: {
    marginTop: 64,
  },

  hamburgerButton: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  hamburgerMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },

  hamburgerMenuTitleItem: {
    fontSize: 18,
    fontWeight: 600,
    padding: 16,
    margin: '2px 0px',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    '&:active': {
      color: themeVariables.colors.primary200,
    },
  },

  hamburgerMenuItemIcon: {
    width: 30,
    height: 30,
    marginRight: 16,
    padding: 6,
    borderRadius: '0.5rem',
    border: `1px solid ${themeVariables.colors.light400}`,
  },

  hamburgerMenuMenuItem: {
    fontSize: 16,
    fontWeight: 400,
    padding: 16,
    margin: '2px 10px',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    '&:active': {
      color: themeVariables.colors.primary200,
    },
  },

  currentLink: {
    lineHeight: 1,
    display: 'block',
    padding: '12px 14px',
    textDecoration: 'none',
    color: themeVariables.colors.primary200,
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.defaultRadius,
  },

  link: {
    lineHeight: 1,
    display: 'block',
    padding: '12px 14px',
    textDecoration: 'none',
    color: theme.colors.gray[7],
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.defaultRadius,
    '&:hover': {
      backgroundColor: dark?.dark ? theme.colors.gray[8] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 8,
    cursor: 'default',
    fontWeight: 600,
  },

  searchKeyword: {
    cursor: 'pointer',
    borderRadius: '0.75rem',
    padding: 6,
    backgroundColor: themeVariables.colors.light100,
    transition: 'all ease 300ms',
    ':hover': {
      transform: 'translateY(-3px)',
    },
    [theme.fn.smallerThan('sm')]: {
      ':hover': {
        transform: 'none',
      },
    },
  },
}));
